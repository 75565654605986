export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'name', label: "Nama", _style:'min-width:100px' },
    { key: 'expired_time', label: "Waktu Expired", _style:'min-width:100px' },
    { key: 'expired_result', label: "Hasil Expired", _style:'min-width:100px' },
    { key: 'max_point_result', label: "Max Hasil Point", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
